import React from "react"
import Fade from 'react-reveal/Fade'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import logo from '../../data/images/logo_gabriel.png'
import intro from '../../data/videos/intro.mp4'

function Hero(props) {
  // var bg = props.data.image.publicURL

  return (
    <Container as={"header"} fluid={true} className="hero py-5" >
      <Row className="hero-wrapper" noGutters> 
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
             <video disableremoteplayback="true" autoPlay playsInline name="media" muted width="400" height="400">
               <source src={intro} type="video/mp4" />
            </video> 
          <Fade up>
            <h1 className="hero-title">{props.data.title}</h1>
          </Fade>
          <Fade up>
            <h2 className="hero-subtitle">{props.data.subtitle}</h2>
          </Fade>
          <Fade>
            <a href="#location"><button className="btn-solid">{props.data.calendar}</button></a>
          </Fade>
        </div>
      </Row>
    </Container>
  )
}

export default Hero